import { urlsInterfaceExtended } from "@/types";
import {
  SettingsApiClientInterface,
  SettingsApi,
} from "@/models/api/settings/settingsApiClient.models";

const urls: urlsInterfaceExtended = {
  fetch: `${process.env.VUE_APP_API_URL}/settings`,
  fetchProportions: `/static/proportions.json`,
  fetchVisualisations: `${process.env.VUE_APP_API_URL}/settings/visualisations`,
};

const settingsApiClient: SettingsApiClientInterface = new SettingsApi(urls);

export default settingsApiClient;
