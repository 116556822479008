import { MenuInterface, subMenuUrlsInterface, SubMenuDataInterface } from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
} from "@/models/http/httpClient.models";

export interface MenuApiClientInterface {
  fetch: () => Promise<MenuInterface[]>;
  fetchSubMenu: () => Promise<SubMenuDataInterface>;
}

export class MenuApi implements MenuApiClientInterface {
  private readonly urls!: subMenuUrlsInterface;

  constructor(urls: subMenuUrlsInterface) {
    this.urls = urls;
  }

  fetch(): Promise<MenuInterface[]> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: false,
    };

    return httpClient.get<MenuInterface[]>(getParameters);
  }

  fetchSubMenu(): Promise<SubMenuDataInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchSubMenu,
      requiresToken: true,
    };

    return httpClient.get<SubMenuDataInterface>(getParameters);
  }
}
