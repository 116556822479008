import { LanguagesInterface, urlsInterface } from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
} from "@/models/http/httpClient.models";

export interface LanguagesApiClientInterface {
  fetch: () => Promise<LanguagesInterface[]>;
}

export class LanguagesApi implements LanguagesApiClientInterface {
  private readonly urls!: urlsInterface;

  constructor(urls: urlsInterface) {
    this.urls = urls;
  }

  fetch(): Promise<LanguagesInterface[]> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: false,
    };

    return httpClient.get<LanguagesInterface[]>(getParameters);
  }
}
