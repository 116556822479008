import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-357d13b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "pageLayout",
  class: "page-layout"
}
const _hoisted_2 = { class: "main-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_AsideComponent = _resolveComponent("AsideComponent")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent),
    (['xs', 'sm', 'md'].includes(_ctx.mq.current))
      ? (_openBlock(), _createBlock(_component_AsideComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createVNode(_component_FooterComponent)
  ], 512))
}