import { Vue } from "vue-class-component";

const svgNS = "http://www.w3.org/2000/svg";
const containerSelector = "#svgHolder";
const baseClassName = "marker";
const groupClassName = "marker-group";
enum ElementType {
  Line = "line",
  StartCircle = "marker-start",
  EndCircle = "marker-end",
  Group = "marker-group",
}

export default class MarkersMixin extends Vue {
  private isMarkerCreated = false;
  static activeMarker: any;

  protected setMarker({
    startPoint,
    endPoint,
  }: {
    startPoint: any;
    endPoint: any;
  }): void {
    const container = document.querySelector(containerSelector);
    const svgEl = container!.querySelector("svg");
    const startCircle = this.createCircle({
      className: ElementType.StartCircle,
      x: startPoint.x,
      y: startPoint.y,
    });
    const endCircle = this.createCircle({
      className: ElementType.EndCircle,
      x: endPoint.x,
      y: endPoint.y,
    });
    const line = this.createLine(
      startPoint.x,
      startPoint.y,
      endPoint.x,
      endPoint.y
    );
    const existingMarkerGroup = svgEl!.querySelector(`.${groupClassName}`);
    const group = this.createGroup();

    this.isMarkerCreated = true;
    MarkersMixin.activeMarker = { startPoint, endPoint };

    if (existingMarkerGroup) {
      existingMarkerGroup!.remove();
    }

    group.append(line, startCircle, endCircle);
    svgEl!.append(group);
  }

  protected removeMarkerGroup(): void {
    const container = document.querySelector(containerSelector);
    const svgEl = container!.querySelector("svg");

    if (svgEl && svgEl!.querySelector(`.${groupClassName}`)) {
      this.isMarkerCreated = false;

      svgEl!.querySelector(`.${groupClassName}`)!.remove();
    }
  }

  private createCircle({
    className,
    x,
    y,
  }: {
    className: string;
    x: string;
    y: string;
  }): any {
    const circle = document.createElementNS(svgNS, "circle");

    circle.classList.add(baseClassName, className);

    circle.setAttributeNS(null, "cx", x);
    circle.setAttributeNS(null, "cy", y);
    circle.setAttributeNS(null, "r", "1.2%");
    circle.setAttributeNS(
      null,
      "style",
      "fill: #F7901E; stroke: #F7901E; stroke: none;"
    );

    return circle;
  }

  private createLine(x1: string, y1: string, x2: string, y2: string): any {
    const line = document.createElementNS(svgNS, "line");

    line.classList.add(baseClassName);

    line.setAttribute("x1", x1);
    line.setAttribute("y1", y1);
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
    line.setAttributeNS(
      null,
      "style",
      "fill: #F7901E; stroke: #F7901E; stroke-width: 1%;"
    );

    return line;
  }

  private createGroup(): any {
    const group = document.createElementNS(svgNS, "g");
    group.classList.add("marker-group");
    return group;
  }
}
