
import { Options, Vue } from "vue-class-component";
import HomeCalculatorPropertiesHeader from "./HomeCalculatorPropertiesHeader/index.vue";
import HomeCalculatorPropertiesList from "./HomeCalculatorPropertiesList/index.vue";

@Options({
  name: "HomeCalculatorProperties",
  components: {
    HomeCalculatorPropertiesHeader,
    HomeCalculatorPropertiesList
  },
})
export default class HomeCalculatorProperties extends Vue {}
