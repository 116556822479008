import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!

  return (_openBlock(), _createBlock(_component_metainfo, null, {
    title: _withCtx(({ content }) => [
      _createTextVNode(_toDisplayString(content ? `${content}  | Anatomy For Sculptors` : `Anatomy For Sculptors`), 1)
    ]),
    description: _withCtx(({ content }) => [
      _createTextVNode(_toDisplayString(content ? content : ``), 1)
    ]),
    _: 1
  }))
}