const config = {
  base: process.env.VUE_APP_MAIN_DOMAIN + "/",
  ecorche: "https://ref.anatomy4sculptors.com/",
  lang: ["en", "lv"],
  systemName: "Human Proportion Calculator 2.0 (Beta)",
  supportEmail: "support@anatomy4sculptors.com",
  api_host: "/api",
  assets_path: "/assets",
  socials: [
    {
      filename: "facebook.svg",
      url: "https://www.facebook.com/anatomy4sculptors",
      name: "facebook",
    },
    {filename: "instagram.svg", url: "https://www.instagram.com/anatomy4sculptors", name: "instagram"},
    {filename: "pinterest.svg", url: "https://www.pinterest.com/Anatomy4Sculptors", name: "pinterest"},
    {
      filename: "artstation.svg",
      url: "https://www.artstation.com/anatomy4sculptors",
      name: "artstation",
    },
    {filename: "youtube.svg", url: "https://www.youtube.com/c/AnatomyForSculptors", name: "youtube"},
    {
      filename: "twitter.svg",
      url: "https://twitter.com/A4Sculptors",
      name: "twitter",
    },
  ],
};

export default config;
