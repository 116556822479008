<template>
  <footer>
    <div class="wrapper">
      <FooterSocial />
    </div>
  </footer>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import FooterSocial from "./TheFooterSocials/index.vue";

@Options({
  name: "FooterComponent",
  components: {
    FooterSocial,
  },
})
export default class FooterComponent extends Vue {}
</script>

<style lang="scss" src="./index.scss" scoped></style>
