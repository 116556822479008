
import { Options, Vue } from "vue-class-component";
import HeaderComponent from "./TheHeader/index.vue";
import AsideComponent from "./TheAside/index.vue";
import FooterComponent from "./TheFooter/index.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "pageLayout",
  components: {
    HeaderComponent,
    AsideComponent,
    FooterComponent
  },
})
export default class PageLayout extends Vue {
  $refs!: {
    pageLayout: HTMLFormElement;
  };
  private mq = useMq();

  private resizeHandler(): void {
    this.setViewPortUnit();
  }

  private setViewPortUnit(): void {
    const vh = window.innerHeight * 0.01;
    this.$refs.pageLayout.style.setProperty("--vh", `${vh}px`);
  }

  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  mounted() {
    this.setViewPortUnit();
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
