import { urlsInterface } from "@/types";
import {
  LanguagesApiClientInterface,
  LanguagesApi,
} from "@/models/api/language/languageApiClient.models";

const urls: urlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/languages`,
};

const languagesApiClient: LanguagesApiClientInterface = new LanguagesApi(urls);

export default languagesApiClient;
