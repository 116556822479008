import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52a7fa8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "link-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_HeaderMenuDropdown = _resolveComponent("HeaderMenuDropdown")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "item", {
        link: _ctx.item.link,
        title: _ctx.item.title
      }, undefined, true),
      (!!_ctx.item.child)
        ? (_openBlock(), _createBlock(_component_SvgIcon, {
            key: 0,
            class: "icon icon--small",
            src: `icons/arrow-down.svg`
          }, null, 8, ["src"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_HeaderMenuDropdown, {
      items: _ctx.item.child
    }, null, 8, ["items"])
  ]))
}