
import { Options, Vue } from "vue-class-component";
import InlineSvg from "vue-inline-svg";

@Options({
  name: "SvgObject",
  components: {
    InlineSvg,
  },
  props: {
    src: {
      type: String,
    },
  },
})
export default class SvgObject extends Vue {
  private basePath?: string = process.env.VUE_APP_RESOURCE_URL;
  private src!: string;

  get getPath(): string {
    if (!this.src) {
      return "";
    }

    return `${this.basePath}/${this.src}`;
  }
}
