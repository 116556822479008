import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-159bab00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calculator" }
const _hoisted_2 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeCalculatorProperties = _resolveComponent("HomeCalculatorProperties")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t("title")), 1)
    ]),
    _createVNode(_component_HomeCalculatorProperties)
  ]))
}