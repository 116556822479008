import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import {
  SettingsInterface,
  SettingsItem,
  PropertiesInterface,
  ActiveInterface,
  Keys,
  ActiveItemInterface,
  Units,
  BaseSettingsItem,
  VisualisationsInterface,
} from "@/types";
import { copy } from "@/utils";
import api from "@/apiClient";

interface SettingsState {
  settings: SettingsInterface;
}

@Module
export default class SettingsModule
  extends VuexModule
  implements SettingsState
{
  private globalLoader = false;
  public settings: SettingsInterface = {
    gender: [],
    part: [],
    type: [],
    properties: { figure: [], foot: [], hand: [], head: [] },
    unit: [],
  };
  private active: ActiveInterface = {};
  private unit: number = Units.Cm;
  private units: BaseSettingsItem[] = [];
  private properties: PropertiesInterface = {
    figure: [],
    foot: [],
    hand: [],
    head: [],
  };
  private visualisations: VisualisationsInterface = {
    figure: {},
    head: {},
    hand: {},
    foot: {},
  };
  private height = 0;

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.settings.fetch().then((data: SettingsInterface) => {
      if (!data || typeof data !== "object") {
        throw Error("Data must be a object!");
      }

      this.setSettings(data);
      this.setGlobalLoader(false);

      if (!Object.keys(this.getActive).length) {
        const activeDefault = copy(this.getActiveDefault);

        for (const key in activeDefault) {
          this.Active({ [key]: activeDefault[key] });
        }
      }
    });
  }

  @Action
  public LoadVisualisations(callback: any): void {
    this.setGlobalLoader(true);

    api.settings.fetchVisualisations().then((data: VisualisationsInterface) => {
      if (!data) {
        throw Error("Data must be a object!");
      }

      this.setVisualisations(data);
      this.setGlobalLoader(false);
      callback();
    });
  }

  @Action
  public Active(active: ActiveItemInterface) {
    this.setActive(active);
  }

  @Action
  public Unit(active: Units) {
    this.setUnit(active);
  }

  @Action
  public Height(height: number) {
    this.setHeight(height);
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  private setUnit(active: Units) {
    this.unit = active;
  }

  @Mutation
  private setHeight(height: number) {
    this.height = height;
  }

  @Mutation
  private setSettings(settings: SettingsInterface) {
    this.settings = settings;
  }

  @Mutation
  public setVisualisations(visualisations: VisualisationsInterface) {
    this.visualisations = visualisations;
  }

  @Mutation
  private setActive(active: ActiveItemInterface) {
    const [key] = Object.keys(active);

    if (key === Keys.Part) {
      const properties = this.settings.properties;
      const tKey = active[
        key
      ].title?.toLowerCase() as keyof PropertiesInterface;

      this.active = { ...this.active, ...{ properties: properties[tKey] } };
    }

    this.active = { ...this.active, ...active };
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get getActiveDefault(): ActiveInterface {
    return {
      gender: this.getGender[0],
      part: this.getPart[0],
      type: this.getType[0],
      properties: this.getProperties.figure,
      unit: this.getUnits,
    };
  }

  get getUnit(): Units {
    return this.unit;
  }

  get getHeight(): number {
    return this.height;
  }

  get getActive(): ActiveInterface {
    return this.active;
  }

  get getSettings(): SettingsInterface {
    return this.settings;
  }

  get getUnits(): Array<string> {
    return this.settings[Keys.Unit]!.map(
      (u: BaseSettingsItem) => u.title
    ) as Array<string>;
  }

  get getProperties(): PropertiesInterface {
    return this.settings[Keys.Properties];
  }

  get getGender(): SettingsItem[] {
    return this.settings[Keys.Gender];
  }

  get getPart(): SettingsItem[] {
    return this.settings[Keys.Part];
  }

  get getType(): SettingsItem[] {
    return this.settings[Keys.Type];
  }

  get getVisualisations(): VisualisationsInterface {
    return this.visualisations;
  }
}
