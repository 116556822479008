import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19414852"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visualization-sidebar-item-list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.type), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source, (d, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: i,
          onClick: ($event: any) => (_ctx.setActive(_ctx.type, d)),
          class: _normalizeClass({'is-active': d.id === _ctx.isActiveItem})
        }, [
          _createVNode(_component_svg_icon, {
            class: _normalizeClass(["icon--small icon--margin-right", [_ctx.type === 'type' && 'circle', _ctx.type === 'gender' && d.name]]),
            src: `icons/${_ctx.getListIcon(d)}.svg`
          }, null, 8, ["class", "src"]),
          _createElementVNode("span", null, _toDisplayString(d.title), 1)
        ], 10, _hoisted_2))
      }), 128))
    ])
  ]))
}