
import { Options, Vue } from "vue-class-component";
import HomeVisualizationSideBarButton from "./HomeVisualizationSideBar/HomeVisualizationSideBarButton/index.vue";
import HomeVisualizationSideBar from "./HomeVisualizationSideBar/index.vue";
import HomeVisualizationContent from "./HomeVisualizationContent/index.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "HomeComponentVisualization",
  components: {
    HomeVisualizationSideBarButton,
    HomeVisualizationSideBar,
    HomeVisualizationContent
  },
})
export default class HomeComponentVisualization extends Vue {
  private mq = useMq();
  private isSideBarOpen = false;

  private closeVisualizationSidebar(): void {
    const activeElement = document.activeElement;

    if (!activeElement!.classList.contains("visualization-side-bar-button")) {
      this.isSideBarOpen = false;
    }
  }

}
