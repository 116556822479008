import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e3a1878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visualization" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeVisualizationSideBarButton = _resolveComponent("HomeVisualizationSideBarButton")!
  const _component_HomeVisualizationContent = _resolveComponent("HomeVisualizationContent")!
  const _component_HomeVisualizationSideBar = _resolveComponent("HomeVisualizationSideBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeVisualizationSideBarButton, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSideBarOpen = !_ctx.isSideBarOpen))
    }),
    _createVNode(_component_HomeVisualizationContent),
    (['lg', 'xl', 'xxl'].includes(this.mq.current))
      ? (_openBlock(), _createBlock(_component_HomeVisualizationSideBar, {
          key: 0,
          onCloseVisualizationSidebar: _ctx.closeVisualizationSidebar,
          "is-opened": _ctx.isSideBarOpen
        }, null, 8, ["onCloseVisualizationSidebar", "is-opened"]))
      : _createCommentVNode("", true)
  ]))
}