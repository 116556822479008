import { createI18n } from "vue-i18n";
import { languagesModule } from "@/store";

interface LocaleInterface {
  [key: string]: any;
}

const loadLocaleMessages = (): LocaleInterface => {
  const locales: any = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: LocaleInterface = {};

  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale: string = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: (languagesModule.getActive && languagesModule.getActive.code) || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
