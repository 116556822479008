const settings = {
  fixedHeader: true,
  theme: {
    mode: {
      dark: true,
    },
  },
  gender: {
    male: true,
    female: true,
  },
  types: {
    new_born: true,
    infant: true,
    young_child: true,
    child: true,
    teenager: true,
    young_adult: true,
    adult: true,
    elderly: true,
  },
  parts: {
    figure: true,
    head: true,
    hand: true,
    foot: true,
  },
};

export default settings;
