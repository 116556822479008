
import CONFIG from "@/config";
import { ConfigInterface } from "@/types";
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "FooterSocial",
  components: {
    SvgIcon,
  },
})
export default class FooterSocial extends Vue {
  private config!: ConfigInterface;

  created(): void {
    this.config = CONFIG;
  }
}
