import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4713d30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calculator-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeComponentCalculator = _resolveComponent("HomeComponentCalculator")!
  const _component_HomeComponentVisualisation = _resolveComponent("HomeComponentVisualisation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HomeComponentCalculator),
    _createVNode(_component_HomeComponentVisualisation)
  ]))
}