import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import config from "@/config";
import Home from "../views/Home/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/shop",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "shop";
    },
  },
  {
    path: "/cart",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "cart";
    },
  },
  {
    path: "/info",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false
    },
  },
  {
    path: "/resources",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false
    },
  },
  {
    path: "/languages",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "languages";
    },
  },
  {
    path: "/books",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false
    },
  },
  {
    path: "/products",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      return false;
    },
  },
  {
    path: "/articles",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "articles";
    },
  },
  {
    path: "/videos",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "videos";
    },
  },
  {
    path: "/pages/contact-us",
    component: () => import("@/views/Default.vue"),
    beforeEnter(to, from, next) {
      window.location.href = config.base + "pages/contact-us";
    },
  },
  {
    path: "/reviews",
    component: () => import("@/views/Default.vue"),
    beforeEnter() {
      window.location.href = config.base + "reviews";
    },
  },
  {
    path: "/ecorche",
    component: () => import("@/views/Default.vue"),
    beforeEnter(to, from, next) {
      window.location.href = config.ecorche;
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
