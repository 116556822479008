import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { LanguagesInterface } from "@/types";
import api from "@/apiClient";

interface LanguagesState {
  languages: LanguagesInterface[];
}

@Module
export default class LanguagesModule extends VuexModule implements LanguagesState {
  private globalLoader = false;
  public languages: LanguagesInterface[] = [];
  private active: LanguagesInterface = { id: 0, name: "", code: "" };

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.languages.fetch().then((data: LanguagesInterface[]) => {
      if (!data || !Array.isArray(data)) {
        throw Error("Data must be a array!");
      }

      this.setLanguages(data);
      this.setGlobalLoader(false);
    });
  }

  @Action
  public Set(active: LanguagesInterface): void {
    this.setActive(active);
  }

  @Mutation
  private setLanguages(languages: LanguagesInterface[]) {
    this.languages = languages;
  }

  @Mutation
  private setActive(active: LanguagesInterface) {
    this.active = active;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  get getLanguages() {
    return this.languages;
  }

  get getActive() {
    return this.active;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }
}
