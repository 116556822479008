import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a62ff76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graphics-wrapper" }
const _hoisted_2 = { class: "graphics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgObject = _resolveComponent("SvgObject")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.getTypeTitle), 1),
      _createElementVNode("div", {
        ref: "graphicsHolder",
        class: _normalizeClass(["graphics-holder", {'is-loaded': _ctx.isLoaded}])
      }, [
        _createElementVNode("div", {
          class: "svg-holder",
          id: "svgHolder",
          style: _normalizeStyle(_ctx.activeVisualisation.transformation)
        }, [
          _createVNode(_component_SvgObject, {
            onLoaded: _ctx.onSvgLoad,
            src: _ctx.activeVisualisation.src
          }, null, 8, ["onLoaded", "src"])
        ], 4)
      ], 2)
    ])
  ]))
}