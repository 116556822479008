import { createStore } from "vuex";

import LanguagesModule from "@/store/modules/languages.store";
import SettingsModule from "@/store/modules/settings.store";
import ProportionsModule from "@/store/modules/proportions.store";
import MenuModule from "@/store/modules/menu.store";

const store = createStore({});

export const languagesModule = new LanguagesModule({ store, name: "languages",});
export const menuModule = new MenuModule({ store, name: "menu" });
export const proportionsModule = new ProportionsModule({ store, name: "proportions",});
export const settingsModule = new SettingsModule({ store, name: "settings" });

export default store;
