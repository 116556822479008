import {
  SettingsInterface,
  urlsInterfaceExtended,
  VisualisationsInterface,
  ProportionsInterface,
} from "@/types";
import {
  httpClient,
  HttpClientGetParameters,
} from "@/models/http/httpClient.models";

export interface SettingsApiClientInterface {
  fetch: () => Promise<SettingsInterface>;
  fetchProportions: () => Promise<ProportionsInterface>;
  fetchVisualisations: () => Promise<VisualisationsInterface>;
}

export class SettingsApi implements SettingsApiClientInterface {
  private readonly urls!: urlsInterfaceExtended;

  constructor(urls: urlsInterfaceExtended) {
    this.urls = urls;
  }

  fetch(): Promise<SettingsInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: false,
    };

    return httpClient.get<SettingsInterface>(getParameters);
  }

  fetchProportions(): Promise<ProportionsInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchProportions,
      requiresToken: false,
    };

    return httpClient.get<ProportionsInterface>(getParameters);
  }

  fetchVisualisations(): Promise<VisualisationsInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchVisualisations,
      requiresToken: true,
    };

    return httpClient.get<VisualisationsInterface>(getParameters);
  }
}
