import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15f549cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visualization-sidebar-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_ItemList = _resolveComponent("ItemList")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["visualization-sidebar", [{ 'is-opened': _ctx.isOpened }]])
  }, [
    _createVNode(_component_CloseButton, {
      class: "sidebar-button-close",
      onClick: _ctx.closeVisualizationSidebar
    }, null, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_perfect_scrollbar, { options: { wheelPropagation: false } }, {
        default: _withCtx(() => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ItemList, {
                key: 0,
                source: _ctx.getGender,
                type: "gender"
              }, null, 8, ["source"]))
            : _createCommentVNode("", true),
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ItemList, {
                key: 1,
                source: _ctx.getPart,
                type: "part"
              }, null, 8, ["source"]))
            : _createCommentVNode("", true),
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_ItemList, {
                key: 2,
                source: _ctx.getType,
                type: "type"
              }, null, 8, ["source"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 2)), [
    [_directive_click_outside, _ctx.closeVisualizationSidebar]
  ])
}