import { ApiClientModels } from "@/models/api/apiClient.models";
import settingsApiClient from "@/apiClient/models/settings";
import languagesApiClient from "@/apiClient/models/languages";
import menuApiClient from "@/apiClient/models/menu";

const apiModelsClient: ApiClientModels = {
  settings: settingsApiClient,
  languages: languagesApiClient,
  menu: menuApiClient,
};

export default apiModelsClient;
