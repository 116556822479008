
import {mixins, Options} from "vue-class-component";
import {settingsModule} from "@/store";
import {MarkersMixin} from "@/mixins";
import {
  ActiveInterface,
  VisualisationsInterface,
  VisualisationItem,
} from "@/types";
import SvgObject from "./SvgObject.vue";
import {copy} from "@/utils";

@Options({
  name: "HomeVisualizationContent",
  components: {
    SvgObject,
  },
  watch: {
    getActive(active) {
      this.setActiveVisualisation(active);
    },
  },
})
export default class HomeVisualizationContent extends mixins(MarkersMixin) {
  $refs!: {
    graphicsHolder: HTMLFormElement;
  };

  protected isLoaded = false;
  private vData: VisualisationItem = {
    id: "",
    src: "",
    markers: [],
    transformation: "",
  };

  private get getActive(): ActiveInterface {
    return settingsModule.getActive;
  }

  private get getVisualisations(): VisualisationsInterface {
    return settingsModule.getVisualisations;
  }

  private get getTypeTitle(): string {
    if (!Object.keys(this.getActive).length) return "";

    return this.getActive!.type!.title as string;
  }

  private get activeVisualisation(): VisualisationItem {
    return this.vData;
  }

  private set activeVisualisation(visualisation: VisualisationItem) {
    this.vData = visualisation;
  }

  private setActiveVisualisation(active: ActiveInterface): void {
    const formatKey = (key: any) => {
      return key!.name as keyof VisualisationsInterface;
    };

    const gender = formatKey(active!.gender);
    const part = formatKey(active!.part);
    const type = formatKey(active!.type)
        .split(" ")
        .join("_") as keyof VisualisationsInterface;
    const data = this.getVisualisations[part as keyof VisualisationsInterface];

    if (data && data![type] && data![type][gender]) {
      this.activeVisualisation = data![type][gender];
    } else {
      this.activeVisualisation = {
        id: "",
        src: "",
        markers: [],
        transformation: "",
      };
    }
  }

  private activeMarkers(index: number): any {
    const shortList = ["newborn", "infant", "young_child", "child"];
    const active = copy(this.getActive);
    const part = active.part?.name as string;
    const type = active.type?.name as string;

    if (part === "head") {
      const excludeProperty = shortList.includes(type)
          ? ["length of eye and space between eyes"]
          : ["length of eye", "space between eyes"];

      active.properties = active.properties?.filter(
          (p: any) => !excludeProperty.includes(p.title)
      );
    }

    const properties = active!.properties;
    const propertyId = properties![index]!.id;

    return this.activeVisualisation.markers.filter(
        (m: any) => m[`markers-${propertyId}`]
    );
  }

  public setGraphicsDimensions(): void {
    const dimensionH = this.$refs.graphicsHolder.clientHeight;
    const dimensionW = this.$refs.graphicsHolder.clientWidth;
    this.$refs.graphicsHolder.style.setProperty("--viewWidth", `${dimensionH}px`);
    this.$refs.graphicsHolder.style.setProperty(
        "--viewHeight",
        `${dimensionW}px`
    );
  }

  private createMarkers(markers: Array<any>): void {
    if (markers.length) {
      const [marker] = markers;

      for (const key in marker) {
        for (const positions of marker[key]) {
          this.setMarker(positions);
        }
      }
    } else {
      this.removeMarkerGroup();
    }
  }

  private resizeHandler(): void {
    this.setGraphicsDimensions();
  }

  private onSvgLoad(e: Event): void {
    this.setGraphicsDimensions();
    this.isLoaded = true;
  }

  mounted() {
    settingsModule.$watch(
        (module) => module.getSettings,
        () => this.setActiveVisualisation(this.getActive)
    );

    this.emitter.on("properties-change", (index: number) => {
      const markers = this.activeMarkers(index);
      this.createMarkers(markers);
    });

    window.addEventListener("resize", this.resizeHandler);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
