
import { Options, setup, Vue } from "vue-class-component";
import HomeComponentCalculator from "./HomeComponentCalculator/index.vue";
import HomeComponentVisualisation from "./HomeComponentVisualisation/index.vue";
import { useMeta } from "vue-meta";

@Options({
  name: "home",
  components: {
    HomeComponentCalculator,
    HomeComponentVisualisation,
  },
})
export default class Home extends Vue {
  protected meta = setup(() =>
      useMeta({
        title: "Human Proportions Calculator",
        description: "Human Proportions Calculator provides artists with measurements for the human figure and its separate body parts, according to the person's sex and age.",
        og: {
          title: "Human Proportions Calculator",
          description: "Human Proportions Calculator provides artists with measurements for the human figure and its separate body parts, according to the person's sex and age.",
        },
      })
  );
}
