
import { defineAsyncComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import { settingsModule } from "@/store";
import { SettingsItem } from "@/types";
import {useMq} from "vue3-mq";

const UnitList = defineAsyncComponent(
    () => import("./HomePropertiesUnitList/index.vue")
);

const PropertiesButton = defineAsyncComponent(
    () => import("./HomePropertiesButton/index.vue")
);

const PropertiesInput = defineAsyncComponent(async () => {
  return await import("./HomePropertiesInput/index.vue");
});

@Options({
  name: "HomeCalculatorPropertiesTop",
  components: {
    PropertiesButton,
    UnitList,
    PropertiesInput,
  },
})
export default class HomeCalculatorPropertiesTop extends Vue {
  private mq = useMq();

  private get isLoading(): boolean {
    return settingsModule.getGlobalLoader;
  }

  private get getGender(): Array<SettingsItem> {
    return settingsModule.getGender;
  }

  private get getType(): Array<SettingsItem> {
    return settingsModule.getType;
  }

  private get getPart(): Array<SettingsItem> {
    return settingsModule.getPart;
  }
}
