
import {PropType} from "vue";
import {Options, Vue} from "vue-class-component";
import {ActiveInterface, SettingsItem, PropertiesItem, Keys} from "@/types";
import {settingsModule} from "@/store";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "VisualisationSideBarList",
  components: {
    SvgIcon
  },
  props: {
    type: {
      type: String,
    },
    source: {
      type: Array as PropType<SettingsItem[]>,
    },
  },
})
export default class VisualisationSideBarList extends Vue {
  private type!: string;

  private get getActive(): ActiveInterface {
    return settingsModule.getActive;
  }

  private get isActiveItem(): string {
    const active = this.getActive[
        this.type as keyof ActiveInterface
        ] as PropertiesItem;

    return active?.id || "";
  }

  private setActive(type: string, obj: SettingsItem): void {
    this.emitter.emit("properties-filter-change", type === Keys.Part);
    settingsModule.Active({ [type]: obj });
  }

  private getListIcon(d: PropertiesItem): string {
    const listData = d;
    const typeIco = "circle-ico";
    const iconName = this.type === "type" ? typeIco : `${listData!.name}-ico`;

    return iconName as string;
  }
}
