import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { ProportionsInterface } from "@/types";
import api from "@/apiClient";

interface ProportionsState {
  proportions: ProportionsInterface;
}

@Module
export default class ProportionsModule extends VuexModule implements ProportionsState {
  private globalLoader = false;
  public proportions: ProportionsInterface = {};

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.settings.fetchProportions().then((data: ProportionsInterface) => {
      if (!data) {
        throw Error("Data must be a array!");
      }

      this.setProportions(data);
      this.setGlobalLoader(false);
    });
  }

  @Mutation
  private setProportions(proportions: ProportionsInterface) {
    this.proportions = proportions;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  get getProportions(): ProportionsInterface {
    return this.proportions;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }
}
