
import { Options, mixins } from "vue-class-component";
import { Header } from "@/mixins";
import Logo from "@/components/ui/BaseIcons/Logo.vue";
import MenuBtn from "./BaseButtonHamburger.vue";
import HeaderMenu from "./TheHeaderMenu/index.vue";
import HeaderMetaInfo from "./TheHeaderMetaInfo.vue";
import StoreBtn from "./BaseButtonStore.vue";
import ShopBtn from "./BaseButtonShop.vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import { useMq } from "vue3-mq";

@Options({
  name: "headerComponent",
  components: {
    Logo,
    MenuBtn,
    HeaderMenu,
    StoreBtn,
    ShopBtn,
    SvgIcon,
    HeaderMetaInfo,
  },
  watch: {
    isMobileMenuOpen(val) {
      this.emitter.emit("toggle-mobile-menu", val);
    },
  },
})
export default class HeaderComponent extends mixins(Header) {
  protected mq = useMq();
  private isMobileMenuOpen = false;

  private resizeHandler(): void {
    this.emitter.emit("toggle-mobile-menu", false);
  }

  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  }

  mounted(): void {
    this.emitter.on("toggle-mobile-menu", (state: boolean) => {
      this.isMobileMenuOpen = state;
    });
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
}
