import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-739610be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "social" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.socials, (social) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["social__item", social.name]),
        key: social.filename
      }, [
        _createElementVNode("a", {
          href: social.url,
          target: "_blank"
        }, [
          _createVNode(_component_svg_icon, {
            local: "",
            class: "icon icon--medium",
            src: require(`./images/` + social.filename)
          }, null, 8, ["src"])
        ], 8, _hoisted_2)
      ], 2))
    }), 128))
  ]))
}