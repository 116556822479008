import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import router from "@/router";
import store from "@/store";
import mitt from "mitt";
import SocialSharing from "vue-social-sharing";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import {Vue3Mq} from "vue3-mq";
import { createMetaManager } from "vue-meta";

import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "@/scss/app.scss";

const emitter = mitt();
const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(SocialSharing)
  .use(PerfectScrollbar)
  .use(Vue3Mq, {
    preset: 'bootstrap5'
  })
  .use(createMetaManager());

app.config.globalProperties.emitter = emitter;
app.mount("#app");
