import { ObjInterface } from "@/types/utils";

export const copy = (obj: any): ObjInterface => {
  return JSON.parse(JSON.stringify(obj));
};

export const getBreakpoint = (): string => {
  const mediaType = "min";
  const xl_mq = window.matchMedia(`(${mediaType}-width: 1200px)`);
  const lg_mq = window.matchMedia(`(${mediaType}-width: 992px)`);
  const md_mq = window.matchMedia(`(${mediaType}-width: 768px)`);
  const sm_mq = window.matchMedia(`(${mediaType}-width: 576px)`);

  if (xl_mq.matches) {
    return "xl";
  }

  if (lg_mq.matches) {
    return "lg";
  }

  if (md_mq.matches) {
    return "md";
  }

  if (sm_mq.matches) {
    return "sm";
  }

  return "xs";
};