
import { Options, Vue } from "vue-class-component";
import { menuModule } from "@/store";
import { MenuInterface } from "@/types";
import { useMq } from "vue3-mq";
import HeaderMenuItem from "./TheHeaderMenuItem/index.vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "HeaderMenu",
  components: { HeaderMenuItem, SvgIcon },
})
export default class HeaderMenu extends Vue {
  protected mq = useMq();
  private mData: MenuInterface[] = [];

  private get isLoading(): boolean {
    return menuModule.getGlobalLoader;
  }

  private get menuData(): MenuInterface[] {
    return this.mData.filter((m: MenuInterface) => m.name != "shop");
  }

  private set menuData(data: MenuInterface[]) {
    this.mData = data;
  }

  created() {
    menuModule.Load();
  }

  mounted(): void {
    menuModule.$watch(
      (module) => module.getMenu,
      (menu) => {
        this.menuData = menu;
      }
    );
  }
}
