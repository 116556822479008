import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f4c2e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "header" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = {
  key: 1,
  class: "grid navigation"
}
const _hoisted_4 = { class: "grid account-and-store-buttons-wrapper" }
const _hoisted_5 = { class: "grid store-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMetaInfo = _resolveComponent("HeaderMetaInfo")!
  const _component_MenuBtn = _resolveComponent("MenuBtn")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_ShopBtn = _resolveComponent("ShopBtn")!
  const _component_StoreBtn = _resolveComponent("StoreBtn")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_HeaderMetaInfo),
    _createElementVNode("div", _hoisted_2, [
      (['xs', 'sm', 'md'].includes(_ctx.mq.current))
        ? (_openBlock(), _createBlock(_component_MenuBtn, {
            key: 0,
            class: _normalizeClass(["grid", { 'menu-btn--active': _ctx.isMobileMenuOpen }]),
            "is-active": _ctx.isMobileMenuOpen,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMobileMenuOpen = !_ctx.isMobileMenuOpen))
          }, null, 8, ["class", "is-active"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Logo),
      (['lg', 'xl', 'xxl'].includes(_ctx.mq.current))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_HeaderMenu)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (['lg', 'xl', 'xxl'].includes(_ctx.mq.current))
          ? (_openBlock(), _createBlock(_component_ShopBtn, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_StoreBtn, { class: "icon" })
        ])
      ])
    ])
  ], 512))
}