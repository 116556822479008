
import { Options, Vue } from "vue-class-component";
import {defineAsyncComponent} from "vue";

const PropertiesList = defineAsyncComponent(
    () => import("./PropertiesList.vue")
);

@Options({
  name: "HomeCalculatorPropertiesList",
  components: {
    PropertiesList,
  },
})
export default class HomeCalculatorPropertiesList extends Vue {}
