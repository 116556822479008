
import {Options, Vue} from "vue-class-component";
import {ClickOutside} from "@/directives";
import CloseButton from "@/components/ui/Buttons/BaseButtonClose.vue";
import ItemList from "./HomeVisualizationSideBarList/index.vue";
import {settingsModule} from "@/store";
import {SettingsItem} from "@/types";

@Options({
  name: "VisualisationSideBar",
  components: {
    CloseButton,
    ItemList
  },
  props: {
    isOpened: {type: Boolean, default: false},
  },
  directives: {
    "click-outside": ClickOutside,
  },
})
export default class VisualisationSideBar extends Vue {
  private isOpened!: false;

  private get isLoading(): boolean {
    return settingsModule.getGlobalLoader;
  }

  private get getGender(): Array<SettingsItem> {
    return settingsModule.getGender;
  }

  private get getType(): Array<SettingsItem> {
    return settingsModule.getType;
  }

  private get getPart(): Array<SettingsItem> {
    return settingsModule.getPart;
  }

  private closeVisualizationSidebar(): void {
    if (this.isOpened) this.$emit("close-visualization-sidebar");
  }
}
