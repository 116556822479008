import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39efcc58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "visualization-side-bar-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_svg_icon, {
      class: "icon icon--medium",
      src: 'icons/visualization-side-bar-button.svg'
    }, null, 8, ["src"])
  ]))
}