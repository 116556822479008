import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { MenuInterface, SubMenuDataInterface } from "@/types";
import api from "@/apiClient";

interface MenuState {
  menu: MenuInterface[];
}

@Module
export default class MenuModule extends VuexModule implements MenuState {
  public menu: MenuInterface[] = [];
  private globalLoader = false;

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.menu.fetch().then((data: MenuInterface[]) => {
      if (!data || !Array.isArray(data)) {
        throw Error("Data must be a array!");
      }

      this.LoadSubMenu(data);
    });
  }

  @Action
  private LoadSubMenu(menu: MenuInterface[]): void {
    api.menu.fetchSubMenu().then((subMenu: SubMenuDataInterface) => {
      this.setMenu({menu, subMenu});
      this.setGlobalLoader(false);
    });
  }

  @Mutation
  private setMenu({menu, subMenu}: {menu: MenuInterface[], subMenu: SubMenuDataInterface}) {
    const subMenusMockup: SubMenuDataInterface = subMenu;

    menu = menu.map((m) => {
      if (subMenusMockup[m.name as keyof SubMenuDataInterface]) {
        m = {
          ...({ child: subMenusMockup[m.name as keyof SubMenuDataInterface] } as MenuInterface),
          ...m,
        };
      }

      return m;
    });

    this.menu = menu;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  get getMenu() {
    return this.menu;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }
}
