export enum Keys {
  Gender = "gender",
  Part = "part",
  Type = "type",
  Properties = "properties",
  Unit = "unit",
}

export enum Gender {
  Male,
  Female,
}

export enum Part {
  Figure,
  Head,
  Hand,
  Foot,
}

export enum Units {
  Cm,
  Ft,
  M
}

export enum Proportions {
  Number,
  String
}
