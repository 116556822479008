
import { Options, Vue } from "vue-class-component";
import { ConfigInterface } from "@/types";
import { languagesModule, settingsModule } from "@/store";
import CONFIG from "@/config";
import PageLayout from "@/components/layouts/index.vue";

@Options({
  name: "app",
  components: {
    PageLayout,
  },
})
export default class App extends Vue {
  private config!: ConfigInterface;
  private themeClass!: string;

  setTheme(): void {
    this.config = CONFIG;
    this.themeClass = this.config.theme.mode.dark
      ? "theme-dark"
      : "theme-default";

    document.body.classList.add(this.themeClass);
  }

  created() {
    this.setTheme();
    settingsModule.LoadVisualisations(() => {
      settingsModule.Load();
    });
    languagesModule.Load();
  }
}
