import axios, { AxiosRequestConfig } from "axios";

/**
 * @description
 * Interface for our HttpClient wrapper
 */
export interface HttpClientModels {
  get<T>(parameters: HttpClientGetParameters): Promise<T>;

  post<T>(parameters: HttpClientPostParameters): Promise<T>;
}

/**
 * HttpClient parameters for "get" operations
 */
export interface HttpClientGetParameters {
  url: string;
  requiresToken: boolean;
}

/**
 * HttpClient parameters for "post" operations
 */
export interface HttpClientPostParameters {
  url: string;
  payload: any;
  requiresToken: boolean;
}

/**
 * @description
 * Wraps http client functionality to avoid directly using a third party npm package like axios
 * and simplify replacement in the future if such npm package would stop being developed or other reasons
 */
export class HttpClient implements HttpClient {
  private getToken(): string {
    const TOKEN_KEY =
      process.env && process.env.VUE_APP_TOKEN_KEY
        ? process.env.VUE_APP_TOKEN_KEY
        : "myapp-token";
    const token = localStorage.getItem(TOKEN_KEY) || "";
    return token;
  }

  get<T>(parameters: HttpClientGetParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken } = parameters;

      // axios options
      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = this.getToken();
        options.headers.RequestVerificationToken = token;
      }

      axios
        .get(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          console.info("------ rejecting ----");
          reject(response);
        });
    });
  }

  post<T>(parameters: HttpClientPostParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, payload, requiresToken } = parameters;

      // axios options
      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = this.getToken();
        options.headers.RequestVerificationToken = token;
      }

      axios
        .post(url, payload, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
}

export const httpClient = new HttpClient();
