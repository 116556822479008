
import { Options, Vue } from "vue-class-component";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "VisualisationSideBarButton",
  components: {
    SvgIcon
  },
})
export default class VisualisationSideBarButton extends Vue {}
