
import {Options, Vue} from "vue-class-component";
import HomeCalculatorProperties from "./HomeCalculatorProperties/index.vue";

@Options({
  name: "HomeComponentCalculator",
  components: {HomeCalculatorProperties},
})
export default class HomeComponentCalculator extends Vue {

}
