import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("button", null, [
    _createVNode(_component_svg_icon, {
      class: "icon icon--small",
      src: `icons/close.svg`
    }, null, 8, ["src"])
  ]))
}